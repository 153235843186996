<template>
    <div class="time-period">
        <div class="page-header">
            <h1>{{ $t('Marketing Management') }} > {{ $t('Time Period') }} > {{ $t('Edit') }}</h1>
        </div>
        <div class="page-content">
            <div class="content-header">
                <h2>{{ $t('Time Period') }}</h2>
            </div>
            <TimePeriodForm @action="updateTime" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Time from '@/lib/time';
import TimePeriodForm from '@/components/marketing/TimePeriodForm.vue';

export default {
    name: 'ViewTimePeriod',
    components: {
        TimePeriodForm
    },
    mounted() {
        this.loadTime();
    },
    methods: {
        async updateTime(form) {
            try {
                const loginInfo = Common.getLoginInfo();
                const timeId = this.$route.params.id;
                const time = await Time.updateTime(this.apiUrl, timeId, form, loginInfo);
                this.$router.push('/time-period');
            } catch (err) {
                console.log(err);
            }
        },
        async loadTime() {
            try {
                const loginInfo = Common.getLoginInfo();
                const timeId = this.$route.params.id;
                const time = await Time.loadTime(this.apiUrl, timeId, loginInfo);
                this.setCurrentTime(time);
            } catch (err) {
                console.log(err);
            }
        },
        ...mapActions(['setCurrentTime']),
    },
    computed: mapState({
        currentTime: state => state.marketing.currentTime,
        langcode: state => state.langcode,
        apiUrl: state => state.apiUrl,
    }),
}
</script>

<style scoped lang="scss">
.content-header {
    display: flex;

    h2 {
        flex: 1;
    }

    .action-button {
        margin-right: 20px;
    }
}
</style>